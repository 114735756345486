import { useMediaQuery } from 'react-responsive';

export const smMobile = '(max-width: 24em)'; // 384px
export const mobile = '(max-width: 30em)'; // 480px
export const tablet = '(max-width: 48em)'; // 768px
export const smLaptop = '(max-width: 63.9375em)'; // 1023px
export const laptop = '(max-width: 70.88em)'; // 1134px

export const smLoptopWidth = '63.9375rem';
export const loptopWidth = '70.88rem';

export const useQuery = () => ({
  isSmMobile: useMediaQuery({ query: smMobile }),
  isMobile: useMediaQuery({ query: mobile }),
  isTablet: useMediaQuery({ query: tablet }),
  isLaptop: useMediaQuery({ query: laptop }),
});
