import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ArrowLeftIcon from 'assets/icons/arrow-left.svg';
import ButtonContainer from 'components/ButtonContainer';
import { tablet, useQuery } from 'styles/breakpoints';
import { leadLanguage } from 'utils/functions';

interface BackButtonProps {
  onClick: () => void;
}

const Container = styled(ButtonContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowLeftIconStyled = styled(ArrowLeftIcon)`
  width: 1.5rem;
  @media ${tablet} {
    width: 1rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
  @media ${tablet} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const BackText = styled.p`
  color: #555770;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.016rem;
  padding-left: 0.625rem;
`;

const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  const { isTablet } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-mw';

  const BackButtonText = () => {
    switch (leadLanguage(quiz)) {
      case 'en':
        return 'Back';
      case 'es':
        return 'Atrás';
      case 'de':
        return 'Zurück';
      case 'it':
        return 'Indietro';
      case 'fr':
        return 'Retour';
      default:
        return 'Back';
    }
  };

  return (
    <Container onClick={onClick}>
      <IconContainer>
        <ArrowLeftIconStyled height={24} width={24} />
      </IconContainer>
      {!isTablet && <BackText>{BackButtonText()}</BackText>}
    </Container>
  );
};

export default React.memo(BackButton);
