import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile, tablet } from 'styles/breakpoints';
import ChevronRight from 'assets/icons/chevron-right.svg';
import CheckBox from './CheckBox';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  showCheckIcon?: boolean;
  showChevronIcon?: boolean;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  hasImage?: boolean;
}>`
  display: flex;
  flex-direction: ${({ hasImage }) => (hasImage ? 'column' : 'row')};
  min-height: ${({ hasImage }) => (hasImage ? 'unset' : '3.5rem')};
  height: ${({ hasImage }) => (hasImage ? '100%' : '3.5rem')};
  width: 100%;
  border: ${({ active }) =>
    active ? '1px solid rgba(66, 61, 129, 0.90);' : ' 1px solid #ECE8E4;'};
  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};
  border-radius: 0.5rem;
  box-shadow: ${({ active }) =>
    active
      ? '0px 0px 1px 0px rgba(40, 41, 61, 0.08), 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16)'
      : '0px 0px 1px 0px rgba(40, 41, 61, 0.08), 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16)'};
  align-items: center;
  justify-content: ${({ hasImage }) =>
    hasImage ? 'flex-start' : 'space-between'};
  text-align: center;
  padding: ${({ hasImage }) =>
    hasImage ? ' 0.84375rem 0.5rem;' : '12px 20px'};
  background-color: ${({ active }) => (active ? '#C1C1DC' : '#FCF7F1')};
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 4rem;
  max-height: 4rem;

  img {
    max-height: 4rem;
  }
`;

const ButtonText = styled(Text).attrs({
  type: 'bodyM',
})<{
  active?: boolean;
  hasImage?: boolean;
}>`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: ${({ hasImage }) => (hasImage ? '500' : '400')};
  line-height: 120%; /* 1.4rem */
  letter-spacing: -0.016rem;
  text-align: ${({ hasImage }) => (hasImage ? 'center' : 'left')};
  padding-right: ${({ hasImage }) => (hasImage ? '0' : '1rem')};
  color: #000000;
  @media ${tablet} {
    font-size: ${({ hasImage }) => (hasImage ? '0.875rem' : '1rem')};
  }
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  showCheckIcon,
  showChevronIcon,
  ...props
}) => (
  <Container
    onClick={onClick}
    active={active}
    maxSelected={maxSelected}
    as="button"
    hasImage={!!ImageComponent}
    {...props}
  >
    {ImageComponent && <ImageContainer>{ImageComponent}</ImageContainer>}
    <ButtonText active={active} hasImage={!!ImageComponent}>
      {' '}
      {children}
    </ButtonText>
    {showCheckIcon && <CheckBox checked={active} quizVariant />}
    {showChevronIcon && <ChevronRight fill={active ? '#504B8B' : '#DAD8D6'} />}
  </Container>
);

export default React.memo(QuizAnswerButton);
