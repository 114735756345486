import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { mobile } from 'styles/breakpoints';

const Button = styled.button`
  font-weight: 400;
  font-size: 1.13rem;
  line-height: 1.46rem;
  color: #f67c37;
  width: 10.56rem;
  height: 3.5rem;
  background-color: #ffffff;
  border: 0.13rem solid #f67c37;
  border-radius: 0.75rem;
  cursor: pointer;

  &:hover {
    font-weight: 600;
    background-color: #f67c37;
    color: #ffffff;
    border: none;
  }

  @media ${mobile} {
    display: none;
  }
`;

export const HeaderButtonAlternative: FC<
  ButtonHTMLAttributes<HTMLButtonElement>
> = props => <Button {...props} />;
