export enum QuestionType {
  Single = 'single',
  Multiple = 'multiple',
  MultipleImages = 'multiple_images',
  Encouragement = 'encouragement',
  Testimonial = 'testimonial',
  Info = 'info',
  InfoImage = 'info_image',
  InfoReview = 'info_review',
  Landing = 'landing',
  Landing2 = 'landing2',
  Image = 'image',
  Slider = 'slider',
  Scale = 'scale',
  Input = 'input',
  PreviousMultiple = 'previous_multiple',
  Insert = 'insert',
  Cost = 'cost',
  PersonalSummary = 'personal_summary',
  Predict = 'predict',
}
