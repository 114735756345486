import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import { Loader, RadioInput, Text } from 'components';
import { PAYMENT_METHOD } from './KiloPayments';
import PaymentMethodsIcon from 'assets/icons/payments/payment-methods-alt.svg';
import PaypalIcon from 'assets/icons/payments/paypal-icon.svg';
import PaypalButtonIcon from 'assets/icons/payments/paypyal-button.svg';
import { mobile } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import PaymentError from 'components/PaymentError';

interface PaymentsProps {
  ids: string[];
  selectedId: string;
  onItemClick: (id: string) => void;
  errorMessage?: string;
}

interface PaymentProps {
  title?: string;
  id: string;
  selectedId: string;
  topBorder?: boolean;
  onItemClick: (id: string) => void;
  Icon: string;
  InnerComponent?: ReactNode;
}

interface PaymentContainerProps {
  topBorder?: boolean;
}

interface KiloPaymentContainerProps {
  selected: boolean;
  isPaypal: boolean;
}

interface PaymentInnerComponentContainerProps {
  checked?: boolean;
  padding?: string;
}

interface PaymentWindowLabels {
  fullName?: string;
  cardNumber?: string;
  paymentBtnTitle?: string;
  creditCardLabel?: string;
}

const PaymentsContainer = styled.div<PaymentInnerComponentContainerProps>`
  margin-top: 0.375rem;
  background-color: #f9f9f9;

  #kilo-payments-error {
    .kp-error-message {
      margin-bottom: 0.375rem;
    }
  }
`;

const PaymentItemsContainer = styled.div<PaymentInnerComponentContainerProps>`
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 0.375rem;
`;

const PaymentContainer = styled.div<PaymentContainerProps>`
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PaypalIconStyled = styled(PaypalIcon)`
  margin-left: 0.75rem;
`;

const StyledPaypalButtonIcon = styled(PaypalButtonIcon)`
  width: 4.3336rem;
  height: 1.375rem;
`;

const KiloPaymentContainer = styled.div<KiloPaymentContainerProps>`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
  position: relative;

  #kilo-payment-stripe {
    .kp-row {
      display: inline;
    }

    label {
      font-family: ${({ theme }) => theme.typography.bodyM600.fontFamily};
      font-size: ${({ theme }) => theme.typography.bodyM600.fontSize};
      font-weight: 400;
      line-height: ${({ theme }) => theme.typography.bodyM600.lineHeight};
      color: ${({ theme }) => theme.colors.dark100};
      display: block;
      margin-bottom: 0.75rem;
    }

    input {
      font-family: ${({ theme }) => theme.typography.bodyM.fontFamily};
      font-size: ${({ theme }) => theme.typography.bodyM.fontSize};
      font-weight: ${({ theme }) => theme.typography.bodyM.fontWeight};
      line-height: ${({ theme }) => theme.typography.bodyM.lineHeight};
      color: ${({ theme }) => theme.colors.dark100};
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 1rem;
      border: 1px solid ${({ theme }) => theme.colors.light100};
      height: 2.5rem;
      ::placeholder {
        color: ${({ theme }) => theme.colors.dark40};
      }
    }

    #kp-stripe-card {
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 1.5rem;
      border: 1px solid ${({ theme }) => theme.colors.light100};
    }

    #kp-stripe-submit {
      width: 100%;
      height: 3rem;
      border-radius: 4px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
        0px 2px 4px rgba(96, 97, 112, 0.16);
      background-color: ${({ theme }) => theme.colors.primary};
      margin: 0 auto;

      .kp-button--text {
        font-family: ${({ theme }) => theme.typography.body600.fontFamily};
        font-size: ${({ theme }) => theme.typography.body600.fontSize};
        font-weight: ${({ theme }) => theme.typography.body600.fontWeight};
        line-height: ${({ theme }) => theme.typography.body600.lineHeight};
        &:before {
          color: ${({ theme }) => theme.colors.light0};
          text-indent: 0;
          display: block;
          margin-top: 0.25rem;
          line-height: initial;
        }
      }
    }
  }
`;

const PaymentInnerComponentContainer = styled.div<PaymentInnerComponentContainerProps>`
  padding: ${({ padding }) => (padding ? padding : '0 1rem 1rem 1rem')};
  display: ${({ checked }) => (checked ? 'block' : 'none')};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin-bottom: 1rem;
`;

const PaypalLoaderWrapper = styled.div`
  position: absolute;
  top: 32.5%;
  left: 50.1%;
  transform: translate(-50%, -50%);
`;

const PaypalButton = styled.div`
  background: ${({ theme }) => theme.colors.paypalBackground};
  border-radius: 1.4375rem;
  width: 0;
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: not-allowed;
`;

const Payment: FC<PaymentProps> = ({
  title,
  id,
  selectedId,
  topBorder,
  onItemClick,
  Icon,
  InnerComponent,
}) => {
  const checked = id === selectedId;

  const handleItemClick = () => {
    onItemClick(id);
  };

  const paymentWindowLabels: PaymentWindowLabels = useSelector(
    (state: AppState) => state?.config?.checkout?.paymentWindowLabels,
  );

  const allInputLabelElements = document.querySelectorAll('.kp-input--label');
  const buttonTitleElement = document.querySelector('.kp-button--text');

  useEffect(() => {
    if (buttonTitleElement) {
      buttonTitleElement.innerHTML =
        paymentWindowLabels?.paymentBtnTitle || 'Submit Secure Paymentfsdfsdf';
    }
  }, [paymentWindowLabels?.paymentBtnTitle, buttonTitleElement]);

  useEffect(() => {
    if (allInputLabelElements?.[0] && allInputLabelElements?.[1]) {
      allInputLabelElements[0].innerHTML =
        paymentWindowLabels?.fullName || 'Full name';
      allInputLabelElements[1].innerHTML =
        paymentWindowLabels?.cardNumber || 'Card number';
    }
  }, [
    allInputLabelElements,
    paymentWindowLabels?.fullName,
    paymentWindowLabels?.cardNumber,
  ]);

  return (
    <>
      <PaymentContainer
        topBorder={topBorder}
        checked={checked}
        onClick={handleItemClick}
      >
        <RadioInput checked={checked} />
        {/* ? Getting an error when trying to style Text component */}
        {!!title && (
          <Text type="bodyM700" style={{ flex: 1, margin: '0 0.75rem' }}>
            {title}
          </Text>
        )}
        <Icon />
      </PaymentContainer>
      {!!InnerComponent && (
        <PaymentInnerComponentContainer
          checked={checked}
          padding={id === PAYMENT_METHOD.paypal ? '0rem 1rem' : undefined}
        >
          <Divider />
          {InnerComponent}
        </PaymentInnerComponentContainer>
      )}
    </>
  );
};

const OldPayments: FC<PaymentsProps> = ({
  ids,
  selectedId,
  onItemClick,
  errorMessage,
}) => {
  const paymentWindowLabels: PaymentWindowLabels = useSelector(
    (state: AppState) => state?.config?.checkout?.paymentWindowLabels,
  );

  const renderPayments = () =>
    ids.map((id, index) => {
      const topBorder = index + 1 === ids.length;

      switch (id) {
        case PAYMENT_METHOD.paypal:
          return (
            <PaymentItemsContainer key={index}>
              <Payment
                key={id}
                title={'Paypal'}
                id={id}
                selectedId={selectedId}
                topBorder={topBorder}
                onItemClick={onItemClick}
                Icon={PaypalIconStyled}
                InnerComponent={
                  <KiloPaymentContainer
                    selected={selectedId === PAYMENT_METHOD.paypal}
                    isPaypal={selectedId === PAYMENT_METHOD.paypal}
                  >
                    <div id={PAYMENT_METHOD.paypal} />
                    <PaypalLoaderWrapper>
                      <PaypalButton>
                        <StyledPaypalButtonIcon />
                      </PaypalButton>
                    </PaypalLoaderWrapper>
                  </KiloPaymentContainer>
                }
              />
            </PaymentItemsContainer>
          );
        case PAYMENT_METHOD.stripe:
          return (
            <PaymentItemsContainer key={index}>
              <Payment
                key={id}
                title={paymentWindowLabels?.creditCardLabel || 'Credit Card'}
                id={id}
                selectedId={selectedId}
                topBorder={topBorder}
                onItemClick={onItemClick}
                Icon={PaymentMethodsIcon}
                InnerComponent={
                  <KiloPaymentContainer
                    selected={selectedId === PAYMENT_METHOD.stripe}
                    isPaypal={selectedId === PAYMENT_METHOD.paypal}
                  >
                    <div id={PAYMENT_METHOD.stripe} />
                    <PaymentError errorMessage={errorMessage} />
                  </KiloPaymentContainer>
                }
              />
            </PaymentItemsContainer>
          );
        default:
          return null;
      }
    });

  return (
    <PaymentsContainer>
      <div key={selectedId} id="kilo-payments-error" />
      {renderPayments()}
    </PaymentsContainer>
  );
};

export default OldPayments;
