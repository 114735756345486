import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  name?: string;
}

export const Seo: React.FC<Props> = ({
  title = 'Eliminate Procrastination | Brainway ',
  description = 'Brainway is the leading habit building and behavior analysis app that helps you stop procrastination and build good habits that last.',
  name = 'Brainway: Eliminate Procrastination',
  children,
}) => (
  <Helmet>
    {title ? <title>{title}</title> : null}
    <meta itemProp="name" content={name} />
    <meta id="description" name="description" content={description} />
    <link rel="canonical" href="https://www.brainway.app" />

    {/* FB tags */}
    <meta property="og:url" content="https://brainway.app" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={name} />
    <meta property="og:description" content={description} />
    {children}
  </Helmet>
);
