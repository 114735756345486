import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface ProgressCountProps {
  total: number;
  count: number;
}

const TextStyled = styled(Text)`
  color: var(--Dark-80, #28293d);
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: -0.016rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: 140%;
    letter-spacing: -0.014rem;
  }
`;
const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => (
  <TextStyled>
    {count}/{total}
  </TextStyled>
);

export default ProgressCount;
