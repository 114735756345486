import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { Container } from 'components';
import { greyDark, white } from 'styles/colors';

export const FooterDefault: React.FC<RouteComponentProps> = () => (
  <FooterWrapper>
    <Container>
      © {new Date().getFullYear()} DoMental. All Rights Reserved.
    </Container>
  </FooterWrapper>
);

const FooterWrapper = styled.footer`
  padding: 2.5rem 0;
  text-align: center;
  color: ${greyDark};
  background: ${white};
`;
