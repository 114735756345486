import { Seo } from 'components';
import React from 'react';
import Landing from './start/quiz/components/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { QuizAnswer, Quiz as QuizType } from 'types/quiz';
import { updateQuizAnswers } from 'state/user/effects';
import { useRouter } from 'apis/history';
import Landing2 from './start/quiz/components/Landing2';
import { QuestionType } from 'utils/constants/questionsTypes';
import Tracking from 'utils/tracking';
import { usePageView } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import { navigate } from 'gatsby';

const Home: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const questions = useSelector((state: AppState) => state.funnel.questions);
  const question = questions[0];
  const { goToQuizStart } = useRouter();
  const quiz = new URLSearchParams(location.search).get('qz') ?? QuizType.Main;
  const q = new URLSearchParams(location.search).get('q') ?? '1';
  const { geolocation, quiz_answers } = useSelector((s: AppState) => s.user);
  const pageUrl = window.location.pathname + window.location.search;

  usePageView({
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
  });

  const logEvent = (data: { category: string; label?: string }) => {
    Tracking.logEvent({
      event: `Quiz question answered - ${quiz}`,
      category: `${q} - ${data.category}`,
      label: data.label,
    });
  };

  const handleLandingOptionSelect = (answer: QuizAnswer) => {
    Tracking.virtualPageView(
      {
        city: encodeURIComponent(
          geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
        ),
        country: geolocation?.iso_country?.toLowerCase(),
        state: normalizeStates(
          geolocation?.iso_country || '',
          geolocation?.iso_state || '',
        ),
        pageUrl: pageUrl,
        pageTitle: window.location.pathname,
        location: geolocation?.iso_country?.toLocaleLowerCase(),
      },
      {
        city: encodeURIComponent(
          geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
        ),
        country: geolocation?.iso_country?.toLowerCase(),
        state: normalizeStates(
          geolocation?.iso_country || '',
          geolocation?.iso_state || '',
        ),
        gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
      },
    );
    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(answer.label),
    });
    delete answer.label;
    dispatch(updateQuizAnswers(answer));
    goToQuizStart();
  };

  return (
    <>
      <Seo />
      {question?.type === QuestionType.Landing ? (
        <Landing
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      ) : (
        <Landing2
          question={question}
          onOptionSelect={handleLandingOptionSelect}
        />
      )}
    </>
  );
});

Home.displayName = 'Home';

export default Home;
