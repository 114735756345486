import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { laptop, mobile, tablet, useQuery } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import Disclaimer from './Disclaimer';
import CircleTick from 'assets/icons/payments/circle-tick.svg';
import theme from 'utils/theme';
import LandingLayout from './LandingLayout';
import quizSvgs from 'utils/constants/quizSvgs';
import LCFooter from 'components/LCFooter';
import PRFooter from 'components/PRFooter';
import { useLocation } from '@reach/router';
import Header from 'components/reviews/Header';
import Triangle from 'assets/icons/triangle.svg';
import WhitePercent from 'assets/icons/white-percent.svg';
import LoopingBanner from 'components/LoopingBanner';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.beige};
`;

const Title = styled(Text)`
  color: #000;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 3.325rem */
  letter-spacing: -0.038rem;
  text-align: left;
  padding-bottom: 1.5rem;
  span {
    color: #f87666;
  }
  @media ${tablet} {
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 2.1rem */
    letter-spacing: -0.024rem;
    padding: 1.5rem 0 1rem;
  }
`;

const CheckmarkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.3125rem;
`;

const CheckTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.dark80};
  margin-left: 0.3125rem;
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;

  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 0.5rem;
  @media ${tablet} {
    justify-content: center;
    column-gap: 1rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  max-width: 9.25rem !important;
  width: 100%;
  height: 3.5rem;
  max-width: 15rem;
  margin-right: 0.75rem;
  flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'};
  @media ${tablet} {
    height: 3rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: 0;
    max-width: unset !important;
  }
`;

const Label = styled(Text)`
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.02rem;
  max-width: 30.125rem;
  width: 100%;
  padding-bottom: 3rem;
  text-align: left;
  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.014rem;
    padding-bottom: 1.5rem;
    text-align: center;
    margin: 0 auto;
  }
`;

const LabelButtons = styled(Text)`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.016rem;
  text-align: left;
  @media ${tablet} {
    font-size: 0.75rem;
    letter-spacing: -0.012rem;
    text-align: center;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: transparent;
  border: 1px solid #030135;
  p {
    color: #030135;
  }
  @media ${tablet} {
    flex-basis: calc(50% - 1rem);
    /* margin-top: 0.5rem; */
  }
`;

const MdpiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Mdpi = styled.div`
  width: 19rem;
  margin-top: 2rem;
  align-self: center;
  @media ${tablet} {
    margin-top: 1.5rem;
  }
`;

const LeftContainer = styled.div`
  max-width: 37.5rem;
  width: 100%;
  margin-right: 1rem;
  @media ${tablet} {
    margin-right: 0;
  }
`;
const RightContainer = styled.div`
  flex: 1;
  min-width: 22rem;
  display: flex;
  justify-content: center;
  max-width: 30.25rem;
  width: 100%;
  @media ${tablet} {
    flex: unset;
  }
`;

const SvgContainer = styled.div`
  max-width: 30.25rem;
  width: 100%;
  svg {
    width: 100%;
    height: auto;
  }
  @media ${tablet} {
    max-width: 16.44144rem;
    max-height: 12.44763rem;
    svg {
      max-height: 12.44763rem;
    }
  }
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const { isTablet } = useQuery();
  const location = useLocation();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-bw';
  const isAddsFlow = location.pathname.includes('start') || quiz !== 'main-bw';
  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={index === 0}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          color={option?.color}
          colorActive={option?.color}
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text color="secondary" type="body500">
              {option?.label}
            </Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };

  const renderGenderButtonsWithBanner = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButtonWithBanner
          key={index}
          color={option?.color}
          fontColor={option?.textColor}
          colorActive={option?.color}
          onClick={() =>
            onOptionSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={true}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButtonWithBanner>
      ))}
    </>
  );

  const renderGenderOptionsWithBanner = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainerWithBanner
        hasBanner={!!question?.bannerTexts}
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtonsWithBanner(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainerWithBanner>
    );
  };

  const renderCheckmarks = (title: string, index: number) => (
    <CheckmarkItem key={index}>
      <CircleTick
        fill={theme.colors.green}
        width={isTablet ? 16 : 24}
        height={isTablet ? 16 : 24}
      />
      <CheckTitle type={isTablet ? 'body' : 'h3400'}>{title}</CheckTitle>
    </CheckmarkItem>
  );

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return (
      <SvgContainer>
        <Svg />
      </SvgContainer>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10);
  }, [location.href, location.pathname]);

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <Container>
      <HeaderWrapper>
        <Header isMobileApp={isAddsFlow} bgHex="#FFFBF8" />
        {question?.bannerTexts && (
          <LoopingBanner
            bannerTexts={question?.bannerTexts.texts}
            primaryTextColor={question?.bannerTexts.primaryColor}
            secondaryTextColor={question?.bannerTexts.secondaryColor}
            primaryGradient={question?.bannerTexts.primaryGradient}
            backgroundColor={question?.bannerTexts.backgroundColor}
            isNotSticky={true}
          />
        )}
      </HeaderWrapper>
      <Wrapper isFullHeight={isAddsFlow}>
        <LeftContainer>
          <Title dangerouslySetInnerHTML={{ __html: question?.title }} />
          <Label
            dangerouslySetInnerHTML={{
              __html: question?.description,
            }}
          />
          <ButtonsTitleContainer>
            <LabelButtons
              dangerouslySetInnerHTML={{ __html: question?.label }}
            />
            {question?.bannerButtonText ? (
              <ButtonsMainConatiner>
                {renderGenderOptionsWithBanner()}
                {question?.bannerButtonText && (
                  <ButtonsBanner>
                    <WhitePercent />
                    <LeftTriangle />
                    <RightTriangle />
                    <ButtonBannerText
                      dangerouslySetInnerHTML={{
                        __html: question?.bannerButtonText,
                      }}
                    />
                  </ButtonsBanner>
                )}
              </ButtonsMainConatiner>
            ) : (
              renderGenderOptions()
            )}
          </ButtonsTitleContainer>

          {/* {!isTablet && (
          <Disclaimer paragraphs={question?.disclaimerParagraphs ?? []} />
        )} */}
        </LeftContainer>
        <RightContainer>{renderSvgImage(question?.svgImage)}</RightContainer>
      </Wrapper>

      {!isAddsFlow ? (
        <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
      ) : (
        <PRFooter disclaimerText={question?.disclaimerParagraphsPR || []} />
      )}
    </Container>
  );
};

export default Landing;

const HeaderWrapper = styled.section`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const DynamicImageStyled = styled(DynamicImage)`
  width: 100%;
  min-width: 523px;
  height: 451px;
  @media ${laptop} {
    min-width: auto;
  }
`;
const DynamicMobileImageStyled = styled(DynamicImage)`
  width: auto;
  height: 250px;
`;

const Wrapper = styled.section<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 78px;
  max-width: 77.25rem;
  width: 100%;
  padding: 6.25rem 3rem 78px;
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 236px);
  ${({ isFullHeight }) =>
    isFullHeight
      ? 'min-height: calc(100vh - 72px)'
      : 'min-height: calc(100vh - 234px)'};
  @media ${tablet} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding: 1rem 1rem 3.5rem;
    height: unset;
    ${({ isFullHeight }) =>
      isFullHeight && 'align-items: center;min-height: calc(100vh - 64px);'}
  }
`;

const ButtonBannerText = styled.p`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.03rem;

  b {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  span {
    font-weight: 700;
    background: radial-gradient(
      71.77% 45.83% at 96.09% 40.38%,
      #edce82 0%,
      #c7a753 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-family: 'Open Sans', sans-serif;
  }

  @media ${tablet} {
    font-size: 0.8125rem;
    letter-spacing: -0.024rem;
  }
`;

const ButtonsBanner = styled.p`
  position: relative;
  display: flex;
  max-width: 38.8125rem;
  width: 100%;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background: #a4202f;
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 0.875rem;
  gap: 0.5rem;
  height: 3.5rem;
  @media ${tablet} {
    max-width: unset;
    height: 3rem;
  }
`;

const LeftTriangle = styled(Triangle)`
  position: absolute;
  top: -0.375rem;
  left: 20%;
`;

const RightTriangle = styled(Triangle)`
  position: absolute;
  top: -0.375rem;
  right: 20%;
`;

const ButtonsMainConatiner = styled.div`
  max-width: 32.4375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 0.75rem;
    max-width: unset;
  }
`;

const GenderButtonWithBanner = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{
  marginRight?: boolean;
  isSingleButton?: boolean;
}>`
  padding: 0 1rem;
  width: 100%;
  height: 3.5rem;

  @media ${tablet} {
    height: 3rem;
  }
`;

const ButtonsContainerWithBanner = styled.div<ButtonsContainerProps>`
  display: flex;
  gap: 1rem;
  @media ${tablet} {
    justify-content: center;
  }
`;
