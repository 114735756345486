import landing from 'assets/icons/quiz/landing.svg';
import q11 from 'assets/icons/quiz/q11.svg';
import stars from 'assets/icons/quiz/stars.svg';
import verified from 'assets/icons/quiz/verified.svg';
import svg1 from 'assets/icons/quiz/svg1.svg';
import svg2 from 'assets/icons/quiz/svg2.svg';
import svg3 from 'assets/icons/quiz/svg3.svg';
import svg4 from 'assets/icons/quiz/svg4.svg';
import svg5 from 'assets/icons/quiz/svg5.svg';
import svg6 from 'assets/icons/quiz/svg6.svg';
import svg7 from 'assets/icons/quiz/svg7.svg';
import svg8 from 'assets/icons/quiz/svg8.svg';
import svg9 from 'assets/icons/quiz/svg9.svg';
import svg10 from 'assets/icons/quiz/svg10.svg';
import svg11 from 'assets/icons/quiz/svg11.svg';
import svg12 from 'assets/icons/quiz/svg12.svg';
import svg13 from 'assets/icons/quiz/svg13.svg';
import svg14 from 'assets/icons/quiz/svg14.svg';
import svg15 from 'assets/icons/quiz/svg15.svg';
import svg16 from 'assets/icons/quiz/svg16.svg';
import svg17 from 'assets/icons/quiz/svg17.svg';
import q19 from 'assets/icons/quiz/q19.svg';

export default {
  landing,
  q11,
  stars,
  verified,
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  svg6,
  svg7,
  svg8,
  svg9,
  svg10,
  svg11,
  svg12,
  svg13,
  svg14,
  svg15,
  svg16,
  svg17,
  q19,
};
