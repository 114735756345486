import React from 'react';
import styled from 'styled-components';

const Checkbox = ({ checked, onChange, quizVariant, props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    {quizVariant ? (
      <StyledCheckboxQuiz checked={checked}>
        <IconQuiz checked={checked} viewBox="0 0 24 24">
          <path
            d="M16.455 7.45474C16.6663 7.25357 16.9475 7.14236 17.2392 7.14456C17.531 7.14677 17.8104 7.26223 18.0187 7.46657C18.2269 7.67091 18.3476 7.94815 18.3553 8.2398C18.363 8.53144 18.2571 8.81468 18.06 9.02974L12.075 16.5147C11.9721 16.6256 11.8479 16.7145 11.7098 16.7763C11.5717 16.838 11.4226 16.8713 11.2714 16.8741C11.1201 16.8769 10.9699 16.8492 10.8296 16.7926C10.6894 16.736 10.5619 16.6517 10.455 16.5447L6.48599 12.5757C6.37546 12.4727 6.28681 12.3485 6.22532 12.2105C6.16383 12.0725 6.13077 11.9236 6.1281 11.7725C6.12544 11.6215 6.15323 11.4714 6.20981 11.3313C6.26639 11.1913 6.35061 11.064 6.45743 10.9572C6.56426 10.8504 6.69151 10.7661 6.8316 10.7096C6.97168 10.653 7.12172 10.6252 7.27278 10.6278C7.42383 10.6305 7.5728 10.6636 7.7108 10.7251C7.8488 10.7866 7.973 10.8752 8.07599 10.9857L11.217 14.1252L16.4265 7.48774L16.455 7.45474Z"
            fill="#FAFDFA"
          />
        </IconQuiz>
      </StyledCheckboxQuiz>
    ) : (
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    )}
  </CheckboxContainer>
);

export default Checkbox;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  margin-bottom: 1px;
`;

const IconQuiz = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: ${({ theme, checked }) => (checked ? theme.colors.newGreen : '#FFF')};
  /* height: ${({ checked }) => (checked ? '24px' : '0px')};
  width: ${({ checked }) => (checked ? '24px' : '0px')}; */
  display: block;
  margin: auto;
  // stroke-width: 4px;
  position: absolute; // <-- Add this line
  /* top: 0; // <-- Add this line */
  /* left: 50%; // <-- Add this line
  transform: translate(-50%, -50%) */
    scale(${props => (props.checked ? '1' : '0')}) translate(2px, 4px);
  opacity: ${props => (props.checked ? '1' : '0')}; // <-- Adjust opacity
  stroke-width: 0.5px;
  transition: transform 0.2s, opacity 0.1s;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.orange : '#FFF'};
  border: 2px solid
    ${({ checked, theme }) => (checked ? theme.colors.orange : '#d6d6d6')};
  border-radius: 4px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const StyledCheckboxQuiz = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${({ checked }) => (checked ? '#423D81' : '#FCF7F1')};
  border: 1px solid ${({ checked }) => (checked ? '#423D81' : '#DAD8D6')};
  border-radius: 24px;
  position: relative; // <-- Add this line
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
  position: relative;
`;

const CheckboxContainer = styled.div`
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
`;
